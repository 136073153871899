import { jsxs, jsx } from "react/jsx-runtime";
import { usePage, useNavigateTo } from "@tecnobit-srl/router";
import { useTranslation } from "react-i18next";
import { reduce, merge } from "lodash-es";
import { createContext, useContext, useMemo, useState, useEffect, useCallback } from "react";
import { useQueryParams } from "@tecnobit-srl/hook-use-query-params";
import { zIndexes } from "@tecnobit-srl/ui-zindexes";
const toastStyle = {
  position: "fixed",
  top: 0,
  right: 0,
  backgroundColor: "white",
  borderRadius: ".25rem",
  padding: ".5rem 1rem",
  margin: "1rem",
  boxShadow: "3px 5px 7px 3px rgba(0,0,0,0.3)",
  zIndex: zIndexes.toast,
  cursor: "pointer"
};
const context = createContext({
  login: async () => {
    return Promise.resolve();
  },
  signup: async () => {
    return Promise.resolve();
  },
  logout: async () => {
    return Promise.resolve();
  },
  user: false
});
const { Provider } = context;
const useAuthentication = () => {
  return useContext(context);
};
function AuthenticationProvider({
  children
}) {
  const page = usePage();
  const navigateTo = useNavigateTo();
  const user = useMemo(() => {
    if (!page.user) {
      return false;
    }
    return reduce(
      Object.keys(page),
      (result, key) => {
        if (key.includes("user-")) {
          merge(result, page[key]);
        }
        return result;
      },
      { ...page.user }
    );
  }, [page]);
  const { t } = useTranslation("core");
  const previousUrl = page.route.url;
  const [loginErrorPopupShown, setLoginErrorPopupShown] = useState(void 0);
  const queryParams = useQueryParams();
  const loginError = useMemo(
    () => queryParams.login_error !== void 0,
    [queryParams]
  );
  useEffect(() => {
    if (!loginError) {
      setLoginErrorPopupShown(false);
    }
  }, [loginError]);
  useEffect(() => {
    if (loginError && loginErrorPopupShown === void 0) {
      setLoginErrorPopupShown(true);
    }
  }, [loginErrorPopupShown, loginError, t]);
  const loginUrl = useMemo(
    () => {
      var _a;
      return ((_a = page.ziggy.routes["oauth.login"]) == null ? void 0 : _a.uri) ?? "/oauth/login";
    },
    [page.ziggy]
  );
  const login = useCallback(
    async (redirectUrl) => {
      await navigateTo(`/${loginUrl}?r=${redirectUrl ?? previousUrl}`);
    },
    [navigateTo, loginUrl, previousUrl]
  );
  const signupUrl = useMemo(
    () => {
      var _a, _b;
      return ((_a = page.ziggy.routes["oauth.signup"]) == null ? void 0 : _a.uri) ?? ((_b = page.ziggy.routes.signup) == null ? void 0 : _b.uri) ?? "/oauth/signup";
    },
    [page.ziggy]
  );
  const signup = useCallback(
    async (redirectUrl) => {
      await navigateTo(`${signupUrl}?r=${redirectUrl ?? previousUrl}`);
    },
    [navigateTo, signupUrl, previousUrl]
  );
  const logoutUrl = useMemo(
    () => {
      var _a;
      return ((_a = page.ziggy.routes["oauth.logout"]) == null ? void 0 : _a.uri) ?? "/oauth/logout";
    },
    [page.ziggy]
  );
  const logout = useCallback(
    async (redirectUrl) => {
      await navigateTo(`${logoutUrl}?r=${redirectUrl ?? previousUrl}`);
    },
    [navigateTo, logoutUrl, previousUrl]
  );
  const providerValues = useMemo(
    () => ({ user, login, signup, logout }),
    [user, login, signup, logout]
  );
  return /* @__PURE__ */ jsxs(Provider, { value: providerValues, children: [
    children,
    loginErrorPopupShown ? (
      // TODO: implementare pacchetto per gestire i toast e i modal
      /* @__PURE__ */ jsx(
        "button",
        {
          type: "button",
          onClick: () => {
            setLoginErrorPopupShown(false);
          },
          style: toastStyle,
          children: t("login_error")
        }
      )
    ) : null
  ] });
}
export {
  AuthenticationProvider,
  useAuthentication
};
